import React from "react";
import Layout from "../components/layout/Layout";
import styled from "@emotion/styled";

const ContactContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
  h3 {
    font-size: 4.5rem;
    font-weight: 700;
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--accent-light);
    margin: 2rem 0;
    background-color: var(--background-theme);
  }
  article {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(13, 74, 99, 0.7);
    section {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-between;

      a {
        width: fit-content;
        height: fit-content;
        font-size: 3rem;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0;
        color: white;
        &:hover {
          color: var(--accent-light);
        }
      }
    }
  }
`;

const Contact = () => {
  return (
    <Layout>
      <ContactContainer>
        <h3>Contact</h3>
        <article>
          <section>
            <a
              href="mailto:webdeveloper@pablonunezruiz.com"
              target="_blank"
              rel="noreferrer"
            >
              <span class="fas fa-envelope fa-2x"></span>&nbsp;E-mail
            </a>
            <a
              href="https://www.linkedin.com/in/pablo-n%C3%BA%C3%B1ez-ruiz-2a3a27211/"
              target="_blank"
              rel="noreferrer"
            >
              <span class="fab fa-linkedin fa-2x"></span>&nbsp;LinkedIn
            </a>
            <a
              href="https://github.com/Panuruiz"
              target="_blank"
              rel="noreferrer"
            >
              <span class="fab fa-github fa-2x"></span>&nbsp;GitHub
            </a>
          </section>
        </article>
      </ContactContainer>
    </Layout>
  );
};

export default Contact;
